import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  endpoint = "https://lunchingapp.herokuapp.com"

  constructor(private http: HttpClient) { }

  getState() {
    return this.http.get(`${this.endpoint}/lunching/state/`);
  }

  getAllNames() {
    return this.http.get(`${this.endpoint}/lunching/choose/`);
    // console.log(req.source.source.source);
    
    console.log('postcode called');
    
  }

  getRandomName() {
    return this.http.get(`${this.endpoint}/lunching/rand/`)
  }

  sendDetails(lunchDetails) {
    return this.http.post(`${this.endpoint}/lunching/choose/`, lunchDetails)
  }

  getLunch() {
    return this.http.get(`${this.endpoint}/lunching/mod/`).pipe(
      map(formData => ({ ...formData, name: formData['who']['name'], time: formData['when'].substr(11, 5) }))
    )
  }

  sendWhere(where) {
    return this.http.post(`${this.endpoint}/lunching/where/`, where)
  }

  updateLunch(updatedLunch) {
    return this.http.put(`${this.endpoint}/lunching/mod/`, updatedLunch);

  }

  sendVote(vote) {
    return this.http.post(`${this.endpoint}/lunching/vote/`, vote);
  }

  getLeaderboard() {
    return this.http.get(`${this.endpoint}/lunching/`)
  }
}
