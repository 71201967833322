import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, MaxLengthValidator } from '@angular/forms';
import { ApiService } from '../../api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HeaderService } from '../../header/header.service';
import { Upcoming } from './upcoming';



@Component({
  selector: 'app-upcoming',
  templateUrl: './upcoming.component.html',
  styleUrls: ['./upcoming.component.scss']
})
export class UpcomingComponent implements OnInit {

  upcomingForm: FormGroup;
  editMode = false;
  submittedForm = {};
  submittedAddress;
  submittedAddressId;
  canVote: boolean;
  lunchesVotedFor: any;

  options = {

    types: ['establishment'],
    componentRestrictions: {
      country: 'GB',
    }

  };

  upcomingLunch = {} as Upcoming;

  constructor(
    private apiService: ApiService,
    private headerService: HeaderService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar
  ) {
    this.upcomingForm = this.formBuilder.group({

      name: '',
      who: '',
      when: '',
      time: '',
      where: [' ', Validators.maxLength(100)],
      attendance: '',
      isoDate: '',

    });
  }

  ngOnInit() {
    this.apiService.getLunch().subscribe((upcoming: Upcoming) => {
      this.upcomingForm.patchValue(upcoming);
      console.log('upcoming looks like', upcoming);
      if (!upcoming.where) {
        upcoming.where = { rName: ' ' };
      }
      this.upcomingLunch = upcoming as Upcoming;
      this.dateConverter(this.upcomingLunch.when);
    });

    this.headerService.checkState();
    this.canVoteCheck();

  }

  dateConverter(when) {
    this.upcomingLunch.isoDate = new Date(when);

    this.upcomingLunch.when = this.upcomingLunch.isoDate.toDateString();

  }

  onSubmit(details) {
    this.editMode = false;

    // details['when'] = details['when'].toISOString();

    let dateVal: any = new Date(details.when);
    let timeVal: any = details.time;

    dateVal = dateVal.getUTCFullYear() + '-' + (dateVal.getUTCMonth() + 1) + '-'
      + (dateVal.getUTCDate());


    let isoT = 'T';
    isoT += timeVal + ':00' + 'Z';
    timeVal = isoT;

    details.when = dateVal + timeVal;

    details.where = {};
    details.where.id = this.submittedAddressId;

    delete details.who;
    delete details.name;


    this.submittedForm = details;

    console.log('submitted form looks like', this.submittedForm);

    this.apiService
      .updateLunch(this.submittedForm)
      .subscribe((res) => {
        console.log('updated lunch api', res);
        this.upcomingLunch = res as Upcoming;
        this.upcomingLunch = {
          ...this.upcomingLunch,
          name: this.upcomingLunch.who[name],
          time: this.upcomingLunch.when.substr(11, 5)
        };
        this.dateConverter(this.upcomingLunch.when);
      });

  }

  // setting the address on submittedAddress variable to add to the onSubmit object
  onChange(e) {
    this.submittedAddress = e;
    // this.upcomingLunch['where'] =
    const whereData = {
      rName: this.submittedAddress
    };

    this.apiService.sendWhere(whereData).subscribe((res) => {
      console.log('where res is', res);

      this.submittedAddressId = res;



    });

  }

  canVoteCheck() {
    if (JSON.parse(localStorage.getItem('lunchesVotedFor'))) {
      return JSON.parse(localStorage.getItem('lunchesVotedFor')).filter(lunch => lunch === this.upcomingLunch.id).length > 0 ?
       this.canVote = false :  this.canVote = true;

      } else {
      localStorage.setItem('lunchesVotedFor', JSON.stringify([]));
      return this.canVote = true;
    }

  }

  countVote(vote) {

    if (this.canVoteCheck()) {
      vote = parseInt(vote, 2);
      this.apiService.sendVote({ number: vote }).subscribe(() => {
        this.lunchesVotedFor = JSON.parse(localStorage.getItem('lunchesVotedFor'));
        this.lunchesVotedFor.push(this.upcomingLunch.id);
        localStorage.setItem('lunchesVotedFor', JSON.stringify(this.lunchesVotedFor));
        this.openSnackBar('Your vote has been registered. Thank you.', 'Dismiss');
      });
    } else {
      this.openSnackBar('You have already voted. Quit playin\'', 'Dismiss');

    }

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
