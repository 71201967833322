import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class RandomiserService {

  namesArr: string[] = [];

  displayName: string = '';

  timeToStop: boolean = false;

  buttonText: string = 'Randomise';

  shuffleInterval;

  randomName: string = '';
  randomId: number;
  displayId: number;

  randomComplete;




  constructor(private apiService: ApiService) { }

  getAllNames() {
    this.apiService.getAllNames().subscribe((res) => {
      console.log('res is', res);
      for (const prop in res) {
        this.namesArr.push(res[prop].name);        
      }
    });
  }

  getRandomName() {

    this.apiService.getRandomName().subscribe((rand) => {
      console.log('the random name is', rand);
      this.randomName = rand['name'];
      this.randomId = rand['id'];
  
    })
  }

  randomiseAnimation() {

  this.shuffleInterval = setInterval(() => {
   
    if(!this.timeToStop) {
     this.namesRandomiser();
     this.randomComplete = false;

   } else if (this.timeToStop) {
     clearInterval(this.shuffleInterval);
     this.displayName = this.randomName;
     this.displayId = this.randomId;
     console.log('the random id is', this.displayId);
     this.timeToStop = false;
     this.buttonText = 'Again';
     this.getRandomName();
     this.randomComplete = true;
   }

  }, 50);
  
}

  namesRandomiser() {
    const randomIndex = Math.floor(Math.random() * this.namesArr.length);
    this.displayName = this.namesArr[randomIndex];
  }

  timeout() {
    this.randomiseAnimation();
    let randomTimeout = setTimeout(() => {
      this.timeToStop = true;
    }, 2000);
  }

}
