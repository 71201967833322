import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';



export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

// const ELEMENT_DATA: PeriodicElement[] = [
//   { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
//   { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
//   { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
//   { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
//   { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
//   { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
//   { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
//   { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
//   { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
//   { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
// ];

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent implements OnInit {



  // displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  displayedColumns: string[] = ['who', 'displayDate', 'where', 'score'];

  // dataSource = ELEMENT_DATA;
  dataSource;

  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit() {
    this.apiService.getLeaderboard().subscribe((res) => {
      console.log('leaderboard looks like', res);

      // for (const data in res) {
      //   console.log(data);

      //   data['name'] = data['who']['name'];
      // }

      this.dataSource = res;
      console.log('data source', this.dataSource);

      this.dataSource.forEach(element => {

        element['name'] = element['who']['name'];
        if (!element['where']) {
          element['where'] = { rName: " " }
          element['where'] = element['where']['rName'];
        } else {
          element['where'] = element['where']['rName'];
        }
        element['displayDate'] = new Date(element['when']).toDateString();
        console.log(element);
        
      });
      
      
     

      // for (const obj in res) {
        // this.dataSource.push(res);
      // }
      
      // console.log(typeof(this.dataSource));
      console.log('date source looks like', this.dataSource);
      
    })
  }

}
