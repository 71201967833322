import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { HeaderService } from '../header/header.service';


@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  submittedEvent: Boolean;
  showRandomiser;

  renderDiv(bool: Boolean) {
    this.submittedEvent = true;
  }



  constructor(
    private apiService: ApiService,
    private headerService: HeaderService
  ) { }

  ngOnInit() {
    this.apiService.getState().subscribe((res) => {
      console.log('state is', res);
      this.headerService.checkState();
      
      if (res['state'] === 0) {
        this.showRandomiser = true;
        // this.headerService.headerTabName = "Spin"


      } else if (res['state'] === 1) {
        
        this.showRandomiser = false;
        // this.headerService.headerTabName = "Upcoming"
      }
      
    })
  }

}
