import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  headerTabName;

  constructor(
    private apiService: ApiService,

  ) { }

  checkState() {
    this.apiService.getState().subscribe((res) => {

      if (res['state'] === 0) {
        this.headerTabName = "Spin"


      } else {

        this.headerTabName = "Upcoming"
      }

    })
  }
}
