import { Component, OnInit } from '@angular/core';
import { RandomiserService } from '../randomiser.service';
import { ApiService } from '../../api.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FormBuilder, Validators } from '@angular/forms';
import { Output, EventEmitter } from '@angular/core';
import { HeaderService } from '../../header/header.service';


@Component({
  selector: 'app-spin',
  templateUrl: './spin.component.html',
  styleUrls: ['./spin.component.scss']
})
export class SpinComponent implements OnInit { 

  events: string[] = [];
  detailsForm: any;
  detailsSubmitted: any;
  chosenName: any;
  // randomComplete: boolean = this.randomiserService.randomComplete;

  @Output() submittedEvent = new EventEmitter<boolean>();

  constructor(
    private randomiserService: RandomiserService,
    private apiService: ApiService,
    private headerService: HeaderService,
    private formBuilder: FormBuilder,
  ) {
    this.detailsForm = this.formBuilder.group({
      attendance: [14, Validators.required],
      date: ['', Validators.required],
      time: ['13:00', Validators.required],
      who: {}
    })
   }

  ngOnInit() {
    this.randomiserService.getRandomName();    
    
    this.randomiserService.getAllNames();

    this.headerService.checkState();

    
  }

  // addDateEvent(type: string, event: MatDatepickerInputEvent<Date>) {
  //   let value = event.value.toISOString().split('T')[0]
  //   this.events.push(value);
  //   console.log('events looks like',this.events);
  // }

  onSubmit(detailsForm) {

    this.detailsSubmitted = detailsForm;
    console.log('details looks like, ', this.detailsSubmitted);

    //Coverting the date to an ISO string and getting rid of the time part in it

    // this.detailsSubmitted.date = this.detailsSubmitted.date.toISOString().split('T')[0];
    this.detailsSubmitted.date = this.detailsSubmitted.date.getUTCFullYear() + '-' +(this.detailsSubmitted.date.getUTCMonth() + 1)+'-' +(this.detailsSubmitted.date.getUTCDate())
    console.log('date looks like', this.detailsSubmitted.date);
    
    
    // adding T&Z to the time in order to make it the ISO format
    let isoT = "T"
    isoT += this.detailsSubmitted.time + ":00" + "Z"
    this.detailsSubmitted.time = isoT;

    // joining the date and time to make an ISO datetime string called 'when'
    this.detailsSubmitted.when = this.detailsSubmitted.date+this.detailsSubmitted.time;

    // deleting the no longer needed date and time properties
    delete this.detailsSubmitted.date
    delete this.detailsSubmitted.time;
    
    // adding name to the object
    // this.detailsSubmitted.who = this.randomiserService.displayName;
    this.detailsSubmitted.who.id = this.randomiserService.displayId;
    // this.chosenName = this.randomiserService.randomName;

    console.log('details now looks like', this.detailsSubmitted);

    // POSTing the submitted information

    this.apiService
    .sendDetails(this.detailsSubmitted)
    .subscribe((res)=> {
      console.log('spin comp post req', res)
      this.submittedEvent.emit(true);

    });

    
  }

}
